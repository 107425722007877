<template>
  <div class="modal is-large"  :class="{'is-active': active}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Agregar Categoría</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Categoría</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="category"
                placeholder="Nombre de la categoría"
                name="category"
                :class="{ 'is-danger': vverrors.first('category') }"
                v-validate="'required'"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">Categoría Padre</label>
            <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('parent_id') }">
              <select
                class="select"
                v-model="parent_id"
                v-validate="'required|excluded:-1'"
                name="parent_id"
              >
                <option value="-1">Selecciona una opción</option>
                <template v-for="(category, index) of catalogCategoriesList">
                  <option :value="category.id" :key="index">{{category.name}}</option>
                </template>
              </select>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button button-givu" @click="addCategory()">Guardar</button>
            </div>
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function
    },
    catalogCategoriesList: {
      type: Array
    },
    saveCategory: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      category: null,
      parent_id: '-1'
    }
  },
  methods: {
    async addCategory () {
      let data = {
        name: this.category,
        parentId: this.parent_id
      }
      if (await this.$validator.validateAll()) {
        this.saveCategory(data)
      }
    }
  }
}
</script>
